import React from "react";
import ScrollTop from "react-scrolltop-button";

const TopButton = () => {
  return (
    <ScrollTop
      text=""
      distance={100}
      breakpoint={768}
      style={{
        border: "none",
        backgroundColor: "transparent",
        padding: "35px",
        right: "5%",
      }}
      className="top-button"
      speed={5} //올라가는 속도 (큰수일수록 느림)
      target={0} //도달위치
      icon=" " //모바일사이즈 text
    ></ScrollTop>
  );
};

export default TopButton;
