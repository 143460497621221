import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

import SubHeader from "../components/sub-header";

const multiSelectStyle = {
  placeholder: {},
  optionContainer: {},
  inputField: {
    margin: "0",
    padding: "0",
  },
  multiselectContainer: {
    height: "56px",
  },
  option: {},
  chips: {
    fontSize: "17px",
    margin: "0 0",
    padding: "0",
  },
  searchBox: {
    border: "none",
    padding: "0 20px",
    display: "flex",
    height: "100%",
  },
};

function ContactUs(props) {
  const { t } = useTranslation(["main"]);
  const [disable, setDisable] = useState(false);

  const tag = [t("contact_us.tag")];
  const url = "contact-us";

  const [mailForm, setMailForm] = useState({
    contactType: t("contact_us.form.contactType.businessPartnership"),
    name: "",
    email: "",
    phone: "",
    company: "",
    title: "",
    comment: "",
  });

  const [phone, setPhone] = useState({
    phone1: "010",
    phone2: "",
    phone3: "",
  });
  const [email, setEmail] = useState({
    emailId: "",
    emailType: "",
  });

  const contactTypeObject = [
    { key: t("contact_us.form.contactType.businessPartnership") },
    { key: t("contact_us.form.contactType.investmentCooperation") },
    { key: t("contact_us.form.contactType.recruitment") },
    { key: t("contact_us.form.contactType.otherInquiries") },
  ];

  useEffect(() => {
    setMailForm((prev) => ({
      ...prev,
      phone: phone.phone1 + phone.phone2 + phone.phone3,
    }));
  }, [phone]);

  useEffect(() => {
    setMailForm((prev) => ({
      ...prev,
      email: email.emailId + "@" + email.emailType,
    }));
  }, [email]);

  const handleSetContactType = (e) => {
    setMailForm((prev) => ({
      ...prev,
      contactType: e.target.value,
    }));
  };

  const handleInputChange = (e, inputKey) => {
    let value = e.target.value;
    if (inputKey === "emailId") {
      setEmail((prev) => ({
        ...prev,
        emailId: value,
      }));
    } else if (inputKey === "emailType") {
      setEmail((prev) => ({
        ...prev,
        emailType: value,
      }));
    } else if (["phone1", "phone2", "phone3"].includes(inputKey)) {
      setPhone((prev) => ({
        ...prev,
        [inputKey]: value,
      }));
    } else {
      setMailForm((prev) => ({
        ...prev,
        [inputKey]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisable(true);
    sendMailApi(mailForm);
  };

  async function sendMailApi(data) {
    fetch("/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    })
      .then(alert(t("contact_us.success")))
      .then(setDisable(false));
  }

  return (
    <div>
      <SubHeader location={tag} url={url} />
      <div id="iSci_sub_contents">
        <div className="iSci_inquiry">
          <h2 className="sub_inquiry">
            <strong className="line">{t("contact_us.contact")}</strong>
          </h2>

          <p>
            {t("contact_us.subtitle")
              .split("\n")
              .map((line) => (
                <div>{line}</div>
              ))}
          </p>

          <div className="form-box">
            <Container className="form-list">
              <Row>
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    className="Group"
                    as={Col}
                    controlId="formGridContactType"
                  >
                    <dl>
                      <dt className="label">
                        <Form.Label>
                          {t("contact_us.form.contactType.title")}
                        </Form.Label>
                      </dt>
                      <dd className="select-box-ui">
                        <Form.Select
                          aria-label="Default select example"
                          className="select"
                          name="contactType"
                          id="contactType"
                          style={multiSelectStyle}
                          onChange={handleSetContactType}
                          required
                        >
                          {contactTypeObject.map((value, index) => (
                            <option key={index} value={value.key}>
                              {value.key}
                            </option>
                          ))}
                        </Form.Select>
                      </dd>
                    </dl>
                  </Form.Group>

                  <Form.Group
                    className="Group"
                    as={Col}
                    controlId="formGridName"
                  >
                    <dl>
                      <dt className="label">
                        <Form.Label>{t("contact_us.form.name")}</Form.Label>
                      </dt>
                      <dd className="input-box">
                        <Form.Control
                          autoComplete="off"
                          className="control"
                          required
                          name="name"
                          type="text"
                          placeholder={t("contact_us.form.name")}
                          onChange={(e) => handleInputChange(e, "name")}
                        />
                      </dd>
                    </dl>
                  </Form.Group>

                  <Form.Group
                    className="Group"
                    as={Col}
                    controlId="formGridEmail"
                  >
                    <dl>
                      <dt className="label">
                        <Form.Label>
                          {t("contact_us.form.email.title")}
                        </Form.Label>
                      </dt>
                      <dd className="input-box">
                        <Form.Control
                          autoComplete="off"
                          className="control"
                          required
                          name="emailId"
                          type="text"
                          placeholder={t("contact_us.form.email.placeholder")}
                          onChange={(e) => handleInputChange(e, "emailId")}
                        />
                        <p>@</p>
                        <Form.Control
                          autoComplete="off"
                          className="control"
                          required
                          as="input"
                          name="emailType"
                          type="text"
                          onChange={(e) => handleInputChange(e, "emailType")}
                        />
                      </dd>
                    </dl>
                  </Form.Group>

                  <Form.Group
                    className="Group"
                    as={Col}
                    controlId="formGridPhone"
                  >
                    <dl>
                      <dt className="label">
                        <Form.Label>{t("contact_us.form.phone")}</Form.Label>
                      </dt>
                      <dd className="input-box">
                        <InputMask
                          autoComplete="off"
                          className="control"
                          type="text"
                          // mask="999"
                          defaultValue="010"
                          required
                          name="phone1"
                          onChange={(e) => handleInputChange(e, "phone1")}
                        ></InputMask>
                        <p>-</p>
                        <InputMask
                          autoComplete="off"
                          className="control"
                          type="text"
                          // mask="9999"
                          required
                          name="phone2"
                          onChange={(e) => handleInputChange(e, "phone2")}
                        ></InputMask>
                        <p>-</p>
                        <InputMask
                          autoComplete="off"
                          className="control"
                          type="text"
                          // mask="9999"
                          required
                          name="phone3"
                          onChange={(e) => handleInputChange(e, "phone3")}
                        ></InputMask>
                      </dd>
                    </dl>
                  </Form.Group>

                  <Form.Group
                    className="Group"
                    as={Col}
                    controlId="formGridCompany"
                  >
                    <dl>
                      <dt className="label">
                        <Form.Label>{t("contact_us.form.company")}</Form.Label>
                      </dt>
                      <dd className="input-box">
                        <Form.Control
                          autoComplete="off"
                          className="control"
                          name="company"
                          required
                          onChange={(e) => handleInputChange(e, "company")}
                        />
                      </dd>
                    </dl>
                  </Form.Group>

                  <Form.Group
                    className="Group"
                    as={Col}
                    controlId="formGridTitle"
                  >
                    <dl>
                      <dt className="label">
                        <Form.Label>{t("contact_us.form.title")}</Form.Label>
                      </dt>
                      <dd className="input-box">
                        <Form.Control
                          autoComplete="off"
                          className="control"
                          name="title"
                          required
                          onChange={(e) => handleInputChange(e, "title")}
                        />
                      </dd>
                    </dl>
                  </Form.Group>

                  <Form.Group
                    className="Group"
                    as={Col}
                    controlId="formGridComment"
                  >
                    <dl>
                      <dt className="label">
                        <Form.Label>
                          {t("contact_us.form.comment.title")}
                        </Form.Label>
                      </dt>
                      <dd className="textarea-box">
                        <Form.Control
                          autoComplete="off"
                          className="control"
                          required
                          name="comment"
                          as="textarea"
                          placeholder={t("contact_us.form.comment.placeholder")}
                          onChange={(e) => handleInputChange(e, "comment")}
                        />
                      </dd>
                    </dl>
                  </Form.Group>

                  {/* 개인정보 수집 및 이용 동의 */}
                  <Form.Group className="mb-3 Group" id="formGridCheckbox">
                    <Form.Check
                      required
                      type="checkbox"
                      label={t("contact_us.form.check")}
                    />
                  </Form.Group>
                  <div className="btn-area">
                    <Button
                      className={disable ? "btn_disabled" : "btn_ok"}
                      type="submit"
                      disabled={disable}
                    >
                      {!disable
                        ? t("contact_us.form.submit")
                        : t("contact_us.sending")}
                    </Button>
                  </div>
                </Form>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
