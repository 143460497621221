import React from "react";
import { useTranslation } from "react-i18next";

//컴포넌트
import SubHeader from "../../components/sub-header";
import id_card from "../../lib/images/id-card.png";
import edit from "../../lib/images/edit.png";
import doc_file from "../../lib/images/doc-file.png";
import conversation from "../../lib/images/conversation.png";
import checked from "../../lib/images/checked.png";

const Recruitment = () => {
  const { t } = useTranslation(["main"]);
  const tag = [t("career.title"), t("career.recruitment")];
  const url = "recruitment";
  return (
    <div>
      <SubHeader location={tag} url={url} />
      <div id="iSci_sub_contents">
        <section class="iSci_recruitment">
          <h2 class="sub_recruitment">
            <strong class="line">{t("career.recruitment")}</strong>
          </h2>
          <p>{t("recruitment.description")}</p>
          <div class="recruitment_contents">
            <h4>{t("recruitment.recruitment_process")}</h4>
            <div class="recruitment_right">
              <ul>
                <li>
                  <ul className="recruit_process">
                    <li>
                      <img src={edit} alt="" />
                      <h2>01</h2>
                      <p>{t("recruitment.application_submission")}</p>
                    </li>
                    <li>
                      <img src={doc_file} alt="" />
                      <h2>02</h2>
                      <p>{t("recruitment.document_screening")}</p>
                    </li>
                    <li>
                      <img src={conversation} alt="" />
                      <h2>03</h2>
                      <p>{t("recruitment.interview_process")}</p>
                      <p>
                        {t("recruitment.first_interview")} <br />
                        {t("recruitment.second_interview")}
                      </p>
                    </li>
                    <li>
                      <img src={checked} alt="" />
                      <h2>04</h2>
                      <p>{t("recruitment.negotiation_and_reference_check")}</p>
                    </li>
                    <li>
                      <img src={id_card} alt="" />
                      <h2>05</h2>
                      <p>
                        {t(
                          "recruitment.result_notification_and_job_confirmation"
                        )}
                      </p>
                    </li>
                  </ul>

                  <p>{t("recruitment.additional_steps_possible")}</p>
                </li>
                <li class="pdt50">
                  <h5>{t("recruitment.software_technology_field")}</h5>
                </li>
                <li class="pdb50">{t("recruitment.coding_test")}</li>
                <li>
                  <h5>{t("recruitment.non_technical_field")}</h5>
                </li>
                <li class="pdb50">
                  {t("recruitment.third_interview_possible")}
                </li>
              </ul>
            </div>
          </div>
          <h4>Recruitment</h4>
          {t("recruitment.no_current_job_postings")}
          {/* <div class="recruitment_area1">
            <div class="recruitment_left">
              <h4>
                상시채용
                <br />
                [신입, 경력]
                <br />
                프로젝트 기획 및 관리(PM)
              </h4>
            </div>
            <div class="recruitment_right">
              <ul>
                <li>
                  <p>
                    [AI사업부] AI,ML 관련 프로젝트 기획,관리,문서작성 인력 모집
                    <br />
                    <br />
                    · 경력무관, 학력무관, 여성우대
                    <br />
                    <br />
                    우대사항:
                    <br /> · 인공지능, 머신러닝 분야의 PM을 꿈꾸시는 분
                    <br /> · 파워포인트 및 기타 문서작성 특기자
                    <br />
                    <br />
                    아래 사람인 링크를 통해 입사지원 바랍니다
                  </p>
                  <button class="apply">
                    <a href="https://www.jobkorea.co.kr/Recruit/GI_Read/38422262?Oem_Code=C1&logpath=1&stext=%EC%95%84%EC%9D%B4%EC%8B%B8%EC%9D%B4%EB%9E%A9&listno=2">
                      모집중
                    </a>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="recruitment_area1">
            <div class="recruitment_left">
              <h4>
                상시채용
                <br />
                [경력] SW개발 직군
              </h4>
            </div>
            <div class="recruitment_right">
              <ul>
                <li>
                  <p>
                    2022년도 SW개발직군 경력 사원 채용
                    <br />
                    <br />
                    담당업무:
                    <br />
                    · Python, Java를 활용한 웹 백엔드 및 인공지능 서비스 개발
                    <br />
                    · Python을 활용한 인공지능 모델 학습 등
                    <br />
                    <br />
                    자격요건:
                    <br /> · 학력: 학력무관
                    <br /> · 경력: 경력 3년 이상
                    <br /> · Python 또는 Java 프로그래밍 능력
                    <br />
                    <br />
                    우대사항:
                    <br /> · 영어 가능자
                    <br /> · 새로운 분야, 기술 습득에 재미와 열의를 느끼시는 분
                    <br />
                    <br />
                    아래 사람인 링크를 통해 입사지원 바랍니다
                  </p>
                  <button class="apply">
                    <a href="https://www.jobkorea.co.kr/Recruit/GI_Read/38816049?Oem_Code=C1&logpath=1&stext=%EC%95%84%EC%9D%B4%EC%8B%B8%EC%9D%B4%EB%9E%A9&listno=1">
                      모집중
                    </a>
                  </button>
                </li>
              </ul>
            </div>
          </div> */}
        </section>
      </div>
    </div>
  );
};

export default Recruitment;
