import React from "react";
import { useTranslation } from "react-i18next";

import SubHeader from "../../components/sub-header";

import engagement from "../../lib/images/engagement.png";
import idea from "../../lib/images/idea.png";
import leadership from "../../lib/images/leadership.png";
import teamwork from "../../lib/images/teamwork.png";

function WelfareSystem(props) {
  const { t } = useTranslation(["main"]);
  const tag = [t("career.title"), t("career.welfare")];

  const url = "welfare-system";

  return (
    <>
      <SubHeader location={tag} url={url} />
      <div id="iSci_sub_contents">
        <section className="iSci_welfare_system">
          <h2 className="sub_welfare_system">
            <strong className="line">{t("welfare.title")}</strong>
          </h2>
          <p>{t("welfare.iscilab_supports")}</p>
          <div className="welfare_system_contents1">
            <div className="welsyscon1_left">
              <h4>{t("welfare.title")}</h4>
            </div>
            <div className="welsyscon1_right">
              <ul>
                <li className="pdt50">
                  <h5>{t("welfare.holiday_bonus")}</h5>
                </li>
                <li className="pdb50">
                  {t("welfare.holiday_bonus_description")}
                </li>
                <li>
                  <h5>{t("welfare.fitness_training_allowance")}</h5>
                </li>
                <li className="pdb50">
                  {t("welfare.fitness_training_allowance_description")}
                </li>
                <li>
                  <h5>{t("welfare.unlimited_coffee_snacks_drinks")}</h5>
                </li>
                <li className="pdb50">
                  {t("welfare.unlimited_coffee_snacks_drinks_description")}
                </li>
                <li>
                  <h5>{t("welfare.meal_support")}</h5>
                </li>
                <li className="pdb50">
                  {t("welfare.meal_support_description")}
                </li>
                <li>
                  <h5>{t("welfare.office_supplies_support")}</h5>
                </li>
                <li className="pdb50">
                  {t("welfare.office_supplies_support_description")}
                </li>
                <li>
                  <h5>{t("welfare.free_organizational_culture")}</h5>
                </li>
                <li className="pdb50">{t("welfare.casual_attire")}</li>
              </ul>
            </div>
          </div>

          <div className="pdt50 welsyscon3_ourpeople">
            <h4>{t("welfare.talent_profile")}</h4>
            <div className="our_people">
              <div>
                <img src={engagement} alt="" />
                <p>{t("welfare.value_individual_opinions")}</p>
              </div>
              <hr />
              <div>
                <img src={idea} alt="" />
                <p>{t("welfare.derive_rational_opinions")}</p>
              </div>
            </div>
            <div className="our_people">
              <div>
                <img src={leadership} alt="" />
                <p>{t("welfare.pursue_common_goal")}</p>
              </div>
              <hr />
              <div>
                <img src={teamwork} alt="" />
                <p>{t("welfare.mutual_support")}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default WelfareSystem;
