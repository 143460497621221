import React from "react";
import { NavHashLink } from "react-router-hash-link";
import SubHeader from "../components/sub-header";

import certification from "../lib/images/아이싸이랩_홈페이지_인증서.jpg";
import award from "../lib/images/아이싸이랩_홈페이지_상장.jpg";
import patent_and_right from "../lib/images/아이싸이랩_홈페이지_특허-및-상표권.jpg";
import { useTranslation } from "react-i18next";

const License = () => {
  const { t } = useTranslation(["main"]);
  const tag = [t("licenses.title")];
  const url = "animal-nose";
  return (
    <div>
      <SubHeader location={tag} url={url} />
      <div id="iSci_sub_contents">
        <div id="anchor" className="intro_company_nav">
          <ul>
            {/* 인증서 */}
            <li>
              <NavHashLink
                className="anchor"
                to="#certification"
                smooth={true}
                duration={500}
              >
                {t("licenses.certificates")}
              </NavHashLink>
            </li>
            {/* 상장 */}
            <li>
              <NavHashLink
                className="anchor"
                to="#award"
                smooth={true}
                duration={500}
              >
                {t("licenses.award")}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                className="anchor"
                to="#patent-and-right"
                smooth={true}
                duration={500}
              >
                {t("licenses.patent_and_right")}
              </NavHashLink>
            </li>
          </ul>
        </div>
        <div className="license_contents">
          <h2>{t("licenses.header")}</h2>
          <span>{t("licenses.content")}</span>
          <section id="certification">
            <h2>{t("licenses.certificates")}</h2>
            <img src={certification} alt="certification" />
          </section>
          <section id="award">
            <h2>{t("licenses.award")}</h2>
            <img src={award} alt="award" />
          </section>
          <section id="patent-and-right">
            <h2>{t("licenses.patent_and_right")}</h2>
            <img src={patent_and_right} alt="patent-and-right" />
          </section>
        </div>
      </div>
    </div>
  );
};

export default License;
