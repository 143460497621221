/* eslint-disable import/no-anonymous-default-export */
export default {
  mode: "ko",
  iSciLab: "iSciLab",
  about: {
    title: "회사소개",
    about: "About Us",
    certificates_and_patents: "인증서 및 특허",
    ceo: "대표 소개",
    organization: "조직",
    history: "연혁",
    partners: "파트너사",
    location: "오시는 길",
  },
  certificates_and_patents: {
    title: "인증서 및 특허",
    certificates: "인증서",
    patent: "특허",
    trademark: "상표권",
  },
  solution: { title: "솔루션", visionAi: "vision AI" },
  project: {
    title: "사업영역",
    animalNose: "동물 바이오 인증",
    visionAiMeta: "비전AI",
    visionAiMetaEng: "AI FOR VISION",
    related: "관련 AI",
  },
  news: {
    title: "뉴스",
    iscilabNews: "iSciLab News",
    IndustryNews: "Industry News",
  },
  career: { title: "인재채용", welfare: "복지제도", recruitment: "신규 모집" },
  contact: "문의",
  footer: {
    address: "서울 관악구 관악로 122 (봉천동, 평창빌딩) 502호",
    businessRegistrationNumber: "사업자등록번호: 119-81-81005",
    ceo: "대표이사: 최형인",
    email: "contacts@iscilab.com",
    tel: "Tel: 02-875-3861",
    fax: "Fax: 02-875-3862",
  },
  copyright: "COPYRIGHT 2024. Ⓒ iSCILAB. ALL RIGHTS RESERVED.",
};
