import React from "react";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";

//컴포넌트
import SubHeader from "../../components/sub-header";

//이미지
import img_visual_nose from "../../lib/images/img_visual_nose.png";
import paradiam from "../../lib/images/paradiam.png";

const AnimalNose = () => {
  const { t } = useTranslation(["main"]);
  const tag = [t("project.title"), t("project.animalNose")];
  const url = "animal-nose";

  return (
    <>
      <SubHeader location={tag} url={url} />
      <div id="iSci_sub_contents">
        <div id="anchor" className="intro_company_nav">
          <ul>
            <li>
              <NavHashLink
                className="anchor_about"
                to="#anchor"
                smooth={true}
                duration={500}
              >
                {t("animal_nose.what_is_nose_print")}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_ceo"
                to="#paradigm"
                smooth={true}
                duration={500}
              >
                {t("animal_nose.paradigm_shift")}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                className="anchor_location"
                to="#verses"
                smooth={true}
                duration={500}
              >
                {t("animal_nose.microchip_vs_biometrics")}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                className="anchor_location"
                to="#how"
                smooth={true}
                duration={500}
              >
                HOW
              </NavHashLink>
            </li>
          </ul>
        </div>
        <section className="iSci_aiSolution">
          <h2 className="sub_aiSolution">
            <strong className="line">{t("animal_nose.nose_print")}</strong>
          </h2>
          <p>{t("animal_nose.nose_print_text_1")}</p>
          <div className="sub_solution_conVisual">
            <img src={img_visual_nose} alt="" />
            <div className="right">
              <h4>{t("animal_nose.text_books")}</h4>
              <br />
              <p>
                <strong className="txt_blue">
                  Miller’s Anatomy of the Dog, 4th Edition, pp.64
                </strong>
                <br />
                “Nose prints, similar to finger prints, ca be used to
                distinguish between individuals”
              </p>
              <br />
              <p>
                <strong className="txt_blue">
                  Anatomy of the Dog, by Klaus-Dieter Budras, Schlutersehe,
                  2010, pp. 6.
                </strong>
                <br />
                “The dermis forms distinct papillae. The epidermis is strikingly
                thin, and its superficial,
                <br />
                cornified layer (stratum corneum) consists of hard 'horn' (hard
                cornified epidermis) that
                <br />
                exhibits a polygonal pattern. The surface pattern is
                individually specific and for this reason
                <br />
                serves to identify the individual animal.”
              </p>
            </div>
          </div>
          <div className="aiSolution_contents">
            <h4 id="paradigm" className="pdt100">
              {t("animal_nose.paradigm_shift_connection")}
            </h4>
            <p>{t("animal_nose.pets_independent_beings")}</p>
            <div className="infog_paradiam">
              <img src={paradiam} alt="" />
            </div>

            <h4 id="verses" className="pdt100">
              {t("animal_nose.biometric_technology_features")}
            </h4>
            <div className="tit_bar">
              {t("animal_nose.microchip_vs_biometrics")}
            </div>
            <table className="table_license nose_contents ">
              <thead>
                <tr colspan="3">
                  <th className="th-1">　</th>
                  <th>{t("animal_nose.microchip_description")}</th>
                  <th>{t("animal_nose.biometric_description")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{t("animal_nose.guaranteed_uniqueness")}</th>
                  <th>×</th>
                  <th>○</th>
                </tr>
                <tr>
                  <th>{t("animal_nose.guaranteed_permanence")}</th>
                  <th>×</th>
                  <th>○</th>
                </tr>
                <tr>
                  <th>{t("animal_nose.guaranteed_compatibility")}</th>
                  <th>×</th>
                  <th>○</th>
                </tr>
                <tr>
                  <th>{t("animal_nose.prevention_of_tampering")}</th>
                  <th>×</th>
                  <th>○</th>
                </tr>
                <tr>
                  <th>{t("animal_nose.ease_of_application")}</th>
                  <th>↓</th>
                  <th>↑</th>
                </tr>
                <tr>
                  <th>{t("animal_nose.non_invasive")}</th>
                  <th>×</th>
                  <th>○</th>
                </tr>
                <tr>
                  <th>{t("animal_nose.loss_prevention")}</th>
                  <th>×</th>
                  <th>○</th>
                </tr>
                <tr>
                  <th>{t("animal_nose.cost")}</th>
                  <th>{t("animal_nose.high")}</th>
                  <th>{t("animal_nose.almost_none")}</th>
                </tr>
                <tr>
                  <th>{t("animal_nose.side_effects")}</th>
                  <th>○</th>
                  <th>×</th>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <section id="how" className="how">
          <h4 className="pdt50">HOW</h4>
          <p className="pdb50">{t("animal_nose.dog_biometrics_video")}</p>
          <iframe
            className="marb100"
            src="https://www.youtube.com/embed/8-Q19xqBoao?autoplay=1&mute=1&start=0"
            width="1280"
            height="720"
            autoplay="1"
            mute="1"
            title="nose movie"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </section>
      </div>
      {/* <!-- Contents End --> */}
    </>
  );
};

export default AnimalNose;
