import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";

const Footer = () => {
  const { t, i18n } = useTranslation(["header"]);
  var now = new Date(); // 현재 날짜 및 시간
  var year = now.getFullYear(); // 연도
  return (
    <section className="footer_background">
      <footer id="iSci_footer">
        <div className="inner_footer">
          <div className="menu">
            <h4>
              <Link to="/about">{t("about.title")}</Link>
            </h4>
            <ul>
              <li>
                <NavHashLink
                  className="sub-link"
                  to="/about#about"
                  smooth={true}
                  duration={500}
                >
                  {t("about.about")}
                </NavHashLink>
              </li>
              <li>
                <NavHashLink
                  className="sub-link"
                  to="/about#ceo"
                  smooth={true}
                  duration={500}
                >
                  {t("about.ceo")}
                </NavHashLink>
              </li>
              {/* <li>
              <NavHashLink
                className="sub-link"
                to="/about#org"
                smooth={true}
                duration={500}
              >
                {t("about.organization")}
              </NavHashLink>
            </li> */}
              <li>
                <NavHashLink
                  className="sub-link"
                  to="/about#history"
                  smooth={true}
                  duration={500}
                >
                  {t("about.history")}
                </NavHashLink>
              </li>
              <li>
                <NavHashLink
                  className="sub-link"
                  to="/about#partners"
                  smooth={true}
                  duration={500}
                >
                  {t("about.partners")}
                </NavHashLink>
              </li>
              <li>
                <NavHashLink
                  className="sub-link"
                  to="/about#location"
                  smooth={true}
                  duration={500}
                >
                  {t("about.location")}
                </NavHashLink>
              </li>
            </ul>
          </div>
          <div className="menu">
            <h4>
              <Link to="/license">{t("certificates_and_patents.title")}</Link>
            </h4>
            <ul>
              <li>
                <NavHashLink to="/license#certification">
                  {t("certificates_and_patents.certificates")}
                </NavHashLink>
              </li>
              <li>
                <NavHashLink to="/license#patent">
                  {t("certificates_and_patents.patent")}
                </NavHashLink>
              </li>
              <li>
                <NavHashLink to="/license#right">
                  {t("certificates_and_patents.trademark")}
                </NavHashLink>
              </li>
            </ul>
          </div>
          <div className="menu">
            <h4>
              <Link to="/animal-nose">{t("project.title")}</Link>
            </h4>
            <ul>
              <li>
                <Link to="/animal-nose">{t("project.animalNose")}</Link>
              </li>
              <li>
                <Link to="/ai-for-vision">{t("project.visionAiMeta")}</Link>
              </li>
              {/* <li>
                <Link to="/related-ai">{t("project.related")}</Link>
              </li> */}
            </ul>
          </div>
          <div className="menu">
            <h4>
              <Link to="/news">{t("news.title")}</Link>
            </h4>
          </div>
          <div className="menu">
            <h4>
              <Link to="/welfare-system">{t("career.title")}</Link>
            </h4>
            <ul>
              <li>
                <Link to="/welfare-system">{t("career.welfare")}</Link>
              </li>
              <li>
                <Link to="/recruitment">{t("career.recruitment")}</Link>
              </li>
            </ul>
          </div>
          <div className="menu">
            <h4>
              <Link to="/contact-us">{t("contact")}</Link>
            </h4>
          </div>
        </div>
        <div className="logo_footer">
          <h2 className="logo_footer_isci">
            <Link to="/">
              <span className="ir">{t("iSciLab")}</span>
            </Link>
          </h2>
          <div className="footer_copy">
            {t("footer.address")}
            <br />
            {t("footer.businessRegistrationNumber")}
            <br />
            {t("footer.ceo")}
            <br />
            {t("footer.email")}
            <br />
            {t("footer.tel")}
            <br />
            {t("footer.fax")}
          </div>
          <div className="footer_copy">
            <p>
              {t("copyright.copyright")} {year}. {t("copyright.text")}
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
