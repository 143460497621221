import React from "react";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";

//컴포넌트
import SubHeader from "../../components/sub-header";

//이미지
import nubes_visio from "../../lib/images/nubes_visio.PNG";
import nubes_label from "../../lib/images/nubes_label.PNG";
import nubes_smart_eye from "../../lib/images/nubes_smart_eye.PNG";
import nubes_aqua_farm from "../../lib/images/nubes_aqua_farm.PNG";
import nubes_mmc from "../../lib/images/nubes_mmc.PNG";
import nubes_fire_watcher from "../../lib/images/nubes_fire_watcher.PNG";

function VisionAi(props) {
  const { t } = useTranslation(["header"]);
  const tag = [t("project.title"), t("project.visionAiMeta")];
  const url = "ai-for-vision-and-metaverse";

  return (
    <>
      <SubHeader location={tag} url={url} />
      <div id="iSci_sub_contents">
        <div id="anchor" className="intro_company_nav">
          <ul>
            <li>
              <NavHashLink
                className="anchor_about"
                to="#anchor"
                smooth={true}
                duration={500}
              >
                Vision AI
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_ceo"
                to="#nubes_visio"
                smooth={true}
                duration={500}
              >
                NUBES VISIO
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_history"
                to="#nubes_label"
                smooth={true}
                duration={500}
              >
                NUBES Label
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_partners"
                to="#nubes_smart_eye"
                smooth={true}
                duration={500}
              >
                NUBES Smart Eye
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_partners"
                to="#nubes_aqua_farm"
                smooth={true}
                duration={500}
              >
                NUBES Aqua Farm
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_location"
                to="#nubes_mmc"
                smooth={true}
                duration={500}
              >
                NUBES MMC
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_location"
                to="#nubes_fire_watcher"
                smooth={true}
                duration={500}
              >
                NUBES Fire watcher
              </NavHashLink>
            </li>
          </ul>
        </div>

        <section class="iSci_aiSolution">
          <h2 class="sub_aiSolution ">
            <strong class="line">NUBES - iScilab A.I. Engine</strong>
          </h2>
          <p className="marb50">(Nubes is ‘Cloud’ in Latin)</p>
          <p className="marb50">
            NUBES는 아이싸이랩에서 자체 개발한 AI Total Solution으로
            <br /> Vision AI등 아이싸이랩 인공지능 솔루션 개발 및 서비스의 기반
            기술 입니다
          </p>

          <div class="aiSolution_contents">
            <h4 id="nubes_visio">NUBES VISIO</h4>
            <div class="infog_paradiam">
              <img src={nubes_visio} alt="" />
            </div>
            <h4 id="nubes_label" class="pdt100">
              NUBES Label
            </h4>
            <div class="infog_paradiam">
              <img src={nubes_label} alt="" />
            </div>
            <h4 id="nubes_smart_eye" class="pdt100">
              NUBES Smart Eye
            </h4>
            <div class="infog_paradiam">
              <img src={nubes_smart_eye} alt="" />
            </div>
            <h4 id="nubes_aqua_farm" class="pdt100">
              NUBES Aqua Farm
            </h4>
            <div class="infog_comparison">
              <img src={nubes_aqua_farm} alt="" />
            </div>
            <h4 id="nubes_mmc" class="pdt100">
              NUBES MMC
            </h4>
            <div class="infog_comparison">
              <img src={nubes_mmc} alt="" />
            </div>
            <h4 id="nubes_fire_watcher" class="pdt100">
              NUBES Fire watcher
            </h4>
            <div class="infog_comparison">
              <img src={nubes_fire_watcher} alt="" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default VisionAi;
