/* eslint-disable import/no-anonymous-default-export */
export default {
  mode: "en",
  iSciLab: "iSciLab",
  about: {
    title: "Company Introduction",
    about: "About Us",
    certificates_and_patents: "Certificates and Patents",
    ceo: "CEO Introduction",
    organization: "Organization",
    history: "History",
    partners: "Partners",
    location: "Location",
  },
  certificates_and_patents: {
    title: "Certificates and Patents",
    certificates: "Certificates",
    patent: "Patent",
    trademark: "Trademark",
  },
  solution: { title: "Solution", visionAi: "Vision AI" },
  project: {
    title: "Business Areas",
    animalNose: "Animal Bio Auth",
    visionAiMeta: "Vision AI",
    visionAiMetaEng: "AI for Vision",
    related: "Related AI",
  },
  news: {
    title: "News",
    iscilabNews: "iSciLab News",
    IndustryNews: "Industry News",
  },
  career: {
    title: "Careers",
    welfare: "Welfare System",
    recruitment: "New Recruitment",
  },
  contact: "Contact",
  footer: {
    address:
      "Republic of Korea, Seoul, Gwanak-gu, Gwanak-ro 122 (Bongcheon-dong, Pyeongchang Building) 502",
    businessRegistrationNumber: "Business Registration Number: 119-81-81005",
    ceo: "CEO: Choi Hyeong-in",
    email: "Email: contacts@iscilab.com",
    tel: "Tel: 02-875-3861",
    fax: "Fax: 02-875-3862",
  },
  copyright: {
    copyright: "COPYRIGHT",
    text: "Ⓒ iSCILAB. ALL RIGHTS RESERVED.",
  },
};
