import React from "react";
import { Link } from "react-router-dom";

import default_img from "../../lib/images/black_logo.png";

const NewsItem = (props) => {
  const {
    activePage,
    index,
    newsList,
    allNewsList,
    url,
    img,
    link,
    title,
    text,
    insertDate,
    key,
  } = props;
  return (
    <div className="news_item">
      <Link
        to={{
          pathname: url,
          state: {
            activePage: activePage,
            title: title,
            text: text,
            insertDate: insertDate,
            key: key,
            img: img,
            link: link,
            index: index,
            newsList: newsList,
            allNewsList: allNewsList,
          },
        }}
      >
        <figure className="news_item_img_container">
          <img className="news_item_img" src={img} alt="" />
        </figure>
        <p>{title}</p>
        <span>{insertDate}</span>
      </Link>
    </div>
  );
};

NewsItem.defaultProps = {
  url: "/news-detail",
  className: "news_item",
  img: default_img,
  title: "Null",
  insertDate: "0000-00-00",
};

export default NewsItem;
