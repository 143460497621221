/* eslint-disable array-callback-return */
import React from "react";
import default_img from "../../lib/images/black_logo.png";

import NewsItem from "./NewsItem";
import { Grid } from "@mui/material";
import { changeDateFormat } from "./News";

const NewsList = (props) => {
  let { activePage, allNewsList, newsList } = props;

  newsList.map((data) => (data.insertDate = changeDateFormat(data.insertDate)));

  return (
    <>
      {newsList && (
        <>
          <div className="news_list">
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {newsList.map((data, index) => {
                return (
                  <Grid item xs={12} md={4}>
                    <NewsItem
                      activePage={activePage}
                      index={index}
                      allNewsList={allNewsList}
                      newsList={newsList}
                      url={`/news/${data.id}`}
                      title={data.title}
                      text={data.text}
                      insertDate={data.insertDate}
                      key={data.id}
                      img={data.img ? data.img : default_img}
                      link={data.link}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
          {/* </Grid> */}
        </>
      )}
    </>
  );
};

NewsList.defaultProps = {
  _id: "6179091283b26e92cff7812e",
  title: "title",
  text: "text",
  insertDate: "21-09-06",
  updateDate: null,
  img: null,
};

export default NewsList;
