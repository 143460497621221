/* eslint-disable import/no-anonymous-default-export */
export default {
  mode: "ko",
  tag: "회사소개",
  nav: {
    about: "About Us",
    certificates_and_patents: "인증서 및 특허",
    ceo: "대표 소개",
    organization: "조직",
    history: "연혁",
    partners: "파트너사",
    location: "오시는 길",
  },
  aboutUs: {
    main: "아이싸이랩은 Intelligence Science Laboratory의 약자로, \n최형인 대표가 서울대 수학연구소 소장 겸 수학과 교수로 재직중이던 2005년에 \n서울대학교 수학연구소 수리기술연구실(Mathematical Technology Laboratory, MTL)을 모태로 설립하여\n인공지능 연구 및 응용을 바탕으로 최고의 인공지능 기업을 지향하고 있습니다.",
  },
  ceo: {
    title: "대표 소개",
    history:
      "Ph.D. in Mathematics, U.C Berkeley \nBS in Electronic Eng., Seoul National University\nProfessor of Mathematics at U. Chicago, Illinois, Iowa (tenured) (1982-1991)\nMember of the Korea Academy of Science and Technology (KAST)\nProfessor of Mathematics, Seoul National University (1991-2018)\nDirector of Mathematics Research Institute, Seoul National University (2000-2018)\nNational Academy of Science Award (2003), Presidential Citation (2018)",
  },
  organization: {
    title: "조  직",
    subTitle:
      "아이싸이랩은 20년이상 연구개발을 함께 한 최형인 대표 제자 박사들 및\n Computer Science 박사, SW엔지니어, 해당 분야 전문가로 구성",
  },
  history: {
    title: "연혁",
    subTitle:
      "아이싸이랩은 20년이상 연구개발을 함께 한 최형인 대표 제자 박사들 및\n Computer Science 박사, SW엔지니어, 해당 분야 전문가로 구성되어 있습니다.",
  },
  partners: { title: "파트너사" },
  location: {
    title: "오시는 길",
    company_ko: {
      title: "아이싸이랩 한국 본사",
      address: "서울시 관악구 관악로 122 평창빌딩\n 502호",
    },
    company_vn: {
      title: " 아이싸이랩 베트남 법인",
      address:
        "153 Nguyễn Đình Chiểu, Phường 6, Quận 3,\nThành phố Hồ Chí Minh 70000 Vietnam",
    },
  },
};
