/* eslint-disable import/no-anonymous-default-export */
export default {
  mode: "en",
  tag: "Company Introduction",
  nav: {
    about: "About Us",
    certificates_and_patents: "Certificates and Patents",
    ceo: "CEO Introduction",
    organization: "Organization",
    history: "History",
    partners: "Partners",
    location: "Location",
  },
  aboutUs: {
    main: "iSciLab is an abbreviation for Intelligence Science Laboratory. \nFounded in 2005 by Professor Choi Hyeong-in, who was serving as the director of the Seoul National University \nMathematical Research Institute and a professor in the Department of Mathematics, \niSciLab originated from the Mathematical Technology Laboratory (MTL) at the Seoul National University. \nWe aspire to be the leading artificial intelligence company \nbased on research and applications in artificial intelligence.",
  },
  ceo: {
    title: "CEO Introduction",
    history:
      "Ph.D. in Mathematics, U.C Berkeley \nBS in Electronic Eng., Seoul National University\nProfessor of Mathematics at U. Chicago, Illinois, Iowa (tenured) (1982-1991)\nMember of the Korea Academy of Science and Technology (KAST)\nProfessor of Mathematics, Seoul National University (1991-2018)\nDirector of Mathematics Research Institute, Seoul National University (2000-2018)\nNational Academy of Science Award (2003), Presidential Citation (2018)",
  },
  organization: {
    title: "Organization",
    subTitle:
      "iSciLab is comprised of Ph.D. graduates mentored by Professor Choi Hyein, as well as computer science Ph.D.s, software engineers, and domain experts with over 20 years of research and development experience.",
  },
  history: {
    title: "History",
    subTitle:
      "iSciLab has been engaged in research and development for over 20 years, \ncomposed of Ph.D. graduates mentored by Professor Choi Hyeong-in, \nas well as computer science Ph.D.s, software engineers, and domain experts in the respective field.",
  },
  partners: { title: "Partners" },
  location: {
    title: "Location",
    company_ko: {
      title: "iSciLab Headquarters - South Korea",
      address: "Seoul, Gwanak-gu, Gwanak-ro 122, Pyeongchang Building, 502",
    },
    company_vn: {
      title: "iSciLab Vietnam Corporation",
      address:
        "153 Nguyễn Đình Chiểu, Ward 6, District 3,\nHo Chi Minh City 70000, Vietnam",
    },
  },
};
