import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import SubHeader from "../../components/sub-header";
import { changeDateFormat } from "./News";

function NewsDetail(props) {
  // const { title, text, insertDate, img, link } = props.location.state;
  const id = props.location.pathname.split("/")[2];

  const [newsDetailData, setNewsDetailData] = useState({
    title: "",
    text: "",
    img: "",
    insertDate: "",
    updateDate: "",
    link: "",
  });

  useEffect(() => {
    axios
      .get(`${window.location.origin}/api/news/detail?id=${id}`)
      .then(({ data }) => {
        let tempData = {
          ...data,
          insertDate: changeDateFormat(data.insertDate),
        };
        setNewsDetailData(tempData);
      })

      .catch((e) => {
        console.error("News Get 요청 실패 \n 원인: ", e);
      });
  }, []);

  // useEffect(() => {
  //   setNewsDetailData((prev) => ({
  //     ...prev,
  //     insertDate: changeDateFormat(prev.insertDate),
  //   }));
  // }, [newsDetailData]);

  const { t } = useTranslation(["header"]);
  const tag = [t("news.title")];
  const url = "news";

  return (
    <>
      <SubHeader location={tag} url={url} />
      <div id="iSci_sub_contents">
        <section className="iSci_news">
          <h2 className="sub_news">
            <strong className="line">NEWS</strong>
          </h2>
          <p>아이싸이랩 새로운 소식을 알려드립니다.</p>
          <div className="news_view">
            <dl>
              <dt>
                <strong className="title">{newsDetailData.title}</strong>
                <strong className="date">
                  <span>{newsDetailData.insertDate}</span>
                </strong>
              </dt>
              <dd className="news_contents">
                <img src={newsDetailData.img} alt="" />
                <p>{newsDetailData.text}</p>
                <a className="news_link" href={newsDetailData.link}>
                  뉴스 본문 링크
                </a>
              </dd>
            </dl>
          </div>
          <div className="btn-area">
            <Link to="/news" className="btn_news_list">
              <span>목 록</span>
            </Link>
          </div>
        </section>
      </div>
    </>
  );
}

NewsDetail.defaultProps = {
  text: "섬유판 생산 기업 유니드(대표 정의승)는 마루 브랜드 올고다(OLGODA) 제품을 소비자가 직접 경험할 수 있도록 지난 19일 서울 강남구 논현동 가구거리에 첫 상설 전시장을 개장했다고 31일 밝혔다.업체에 따르면 올고다 상설 전시장에서는 표면 및 코어 소재, 디자인에 따라 구분된 다양한 종류의 올고다 마루를 만날 수 있다.신소재인 올코어(OL-core)로 만들어 기존 합판 강마루 보다 내구성, 특히 ‘찍힘’에 강하며 SE0 등급소재로만 이뤄져 친환경적인 올고다 마루의 특징 확인은 물론 솔리드, 밸런스,그루브, 시그니처 등 4가지 라인 각각의 특징을 알아보고 집안 환경 및 인테리어에 따른 추천 제품 상담도 가능하다.",
  url: "/news-detail",
  className: "news01",
  title: "Null",
  insertDate: "0000-00-00",
};
export default NewsDetail;
