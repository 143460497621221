import React, { Component } from "react";
import Slider from "react-slick";

import arrow_left from "../lib/images/arrow_left.png";
import arrow_right from "../lib/images/arrow_right.png";
import img_main_visual_brain from "../lib/images/img_mainvisual_brain.png";
import img_sitting_dog from "../lib/images/img_sitting_dog.png";
import img_main_visual_network from "../lib/images/img_mainvisual_network.png";

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <img src={arrow_right} className={className} alt="" onClick={onClick} />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <img src={arrow_left} className={className} alt="" onClick={onClick} />
  );
}

function GetProps(props) {
  const { onClick } = props;
  doNext(onClick);
  return null;
}

function doNext(func) {
  const donext = func;
  // console.log("donext 실행");
  donext();
  return null;
}

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true, // 페이징 보이기
      infinite: true, // 무한 슬라이딩
      speed: 800, // 넘어가는 빠르기
      slidesToShow: 1, // 한 페이지당 보여줄 게시물 수
      slidesToScroll: 1, //스크롤당 넘어가는 갯수
      autoplay: true, //자동 플레이
      autoplaySpeed: 5000, //ms, 자동으로 넘어가는 시간
      pauseOnFocus: true,
      arrows: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    return (
      <div>
        <Slider
          {...settings}
          appendDots={(dots) => (
            <ul
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {dots}
            </ul>
          )}
        >
          <div className="visual_copy">
            <div className="visual_copy_text">
              <p className="copy_txt01">
                인공지능의
                <br />
                잠재력을 깨우다
              </p>
              <p className="copy_txt02">
                Unlock the potential of Artificial Intelligence
                <br />
                for the solutions of real-world problems
              </p>
            </div>
            <div class="visual_img">
              <img src={img_main_visual_brain} alt="main_visual03" />
            </div>
          </div>

          <div className="visual_copy">
            <div className="visual_copy_text">
              <p className="copy_txt01">
                반려동물이 행복한
                <br />
                생태계를 만들다
              </p>
              <p class="copy_txt02">
                Pet-Tech to Pet ECO-SYSTEM
                <br />
              </p>
            </div>
            <div class="visual_img">
              <img src={img_sitting_dog} alt="main_visual02" />
            </div>
          </div>
          <div className="visual_copy">
            <div className="visual_copy_text">
              <p class="copy_txt01">
                최고의 비전 AI로
                <br />
                시야를 넓히다
              </p>
              <p class="copy_txt02">
                Broaden your horizons with the best vision AI
              </p>
            </div>
            <div class="visual_img">
              <img src={img_main_visual_network} alt="main_visual03" />
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
