/* eslint-disable import/no-anonymous-default-export */
export default {
  mode: "ko",
  iSciLab: "iSciLab",
  about: {
    main: "iSciLab is an abbreviation for Intelligence Science Laboratory.",
    sub: "We are a leading artificial intelligence company that focuses on mathematical-based AI research, application, and related technology development and services.",
  },
  our_work: {
    main: "iSciLab conducts research in artificial intelligence (deep learning and machine learning), finance, optimization, and various other fields. We develop proprietary technologies, apply for patents, and commercialize our technology through the establishment of strategic partnerships. Additionally, iSciLab collaborates with partners for joint research and development.",
    product1: "Animal Nose Biometric Recognition Technology",
    product2: "Vision AI",
    product3: "Related AI",
    button: "PAST WORKS",
  },
  news: {
    label: "Stay updated with the latest news from iSciLab.",
    button: "See More News",
  },

  main_contact_us: {
    main: "iSciLab engages in research in artificial intelligence (deep learning and machine learning), finance, optimization, and various other fields. \nWe develop proprietary technologies, apply for patents, and commercialize our technology through the establishment of strategic partnerships. \nAdditionally, iSciLab collaborates with partners for joint research and development.",
    button: "Contact Us",
  },
  advertise: {
    GWANGJU_2022: "Gwangju 2022 AI Tech+​",
    ISCILAB_PET_SERVICE:
      "iSciLab's Pet Biometric Registration\nand Search Service",
    YOON_SEOK_YEOL: "President Yoon Seok-yeol's \nDemonstration Participation!",
    RESEMBLING_DOGS:
      "Two dogs that look alike! Can you distinguish them just by nose photos?!\nCheck it out in the video!",
  },
  licences: {
    header: "Certificates and Patents",
    certificates: "Certificates",
    patent: "Patent",
    trademark: "Trademark",
    title: "iSciLab's Intellectual Property Rights",
    content:
      "Biometric recognition algorithm and technology, registration and search, devices, business models, etc.\n\nOriginal technology and business patents, 18 registered patents domestically and internationally, \nand numerous additional patent applications are in progress.",
  },
  project: {
    title: "Business Areas",
    animalNose: "Animal Biometrics",
    visionAiMeta: "Vision AI",
    visionAiMetaEng: "AI FOR VISION",
    related: "Related AI",
  },
  animal_nose: {
    what_is_nose_print: "What is Nose Print",
    paradigm_shift: "Paradigm Shift",
    microchip_vs_biometrics: "Microchip vs. Biometrics",
    nose_print: "Nose Print",
    nose_print_text_1:
      "Nose Print is a unique pattern distributed \nthroughout the entire nose of an animal, similar to human fingerprints. \nIt remains unchanged throughout an animal's lifetime, enabling individual identification.",
    text_books:
      "Representative veterinary textbooks \nexplaining Nose Print as a means of identification",
    paradigm_shift_connection: "Paradigm Shift in Human and Pet Relationships",
    pets_independent_beings:
      "Now, pets are considered independent living beings rather than possessions or objects owned by humans",
    biometric_technology_features: "Biometric Recognition Technology Features",
    microchip_vs_biometrics: "Microchip(RFID) vs. Biometric Recognition",
    microchip_description:
      "Microchip (RFID)\n(Injected into the body or attached to a collar)",
    biometric_description: "Biometric Recognition",
    guaranteed_uniqueness: "Guaranteed Uniqueness",
    guaranteed_permanence: "Guaranteed Permanence",
    guaranteed_compatibility: "Guaranteed Compatibility",
    prevention_of_tampering: "Prevention of Tampering",
    ease_of_application: "Ease of Application",
    non_invasive: "Non-Invasive",
    loss_prevention: "Loss Prevention",
    cost: "Cost",
    high: "High",
    almost_none: "Almost None",
    side_effects: "Side Effects",
    dog_biometrics_video:
      "Demonstration video of registering, searching, and identifying dog nose prints",
  },
  news: {
    title: "뉴스",
    iscilabNews: "iSciLab News",
    IndustryNews: "Industry News",
    label: "iSciLab brings you the latest news.",
  },
  career: {
    title: "Recruitment",
    welfare: "Welfare System",
    recruitment: "New Recruitment",
  },
  welfare: {
    title: "Welfare System",
    iscilab_supports:
      "iSciLab generously supports the happiness and growth of its crew members.",
    holiday_bonus: "Holiday Bonus",
    holiday_bonus_description:
      "We provide gift vouchers for a joyful holiday season. (New Year, Chuseok)",
    fitness_training_allowance: "Fitness Training Allowance",
    fitness_training_allowance_description:
      "We support monthly fitness training allowances.",
    unlimited_coffee_snacks_drinks: "Unlimited Coffee, Snacks, and Drinks",
    unlimited_coffee_snacks_drinks_description:
      "We offer unlimited coffee, snacks, and drinks for energy replenishment.",
    meal_support: "Meal Support",
    meal_support_description:
      "We provide meal support for lunch and dinner (for those who wish).",
    office_supplies_support: "Office Supplies Support",
    office_supplies_support_description:
      "We supply all necessary office supplies for work.",
    free_organizational_culture: "Free Organizational Culture",
    casual_attire:
      "Employees work in the most comfortable attire without any restrictions.",
    talent_profile: "Talent Profile",
    value_individual_opinions: "We value individual opinions.",
    derive_rational_opinions: "We derive rational opinions.",
    pursue_common_goal: "We strive towards a common goal.",
    mutual_support: "We support each other.",
  },
  recruitment: {
    title: "New Recruitment at iSciLab",
    description: "iSciLab is recruiting individuals who will grow together.",
    recruitment_process: "Recruitment Process",
    application_submission: "Application Submission",
    document_screening: "Document Screening",
    interview_process: "Interview Process",
    first_interview: "First Interview",
    second_interview: "Second Interview (Executive Interview)",
    negotiation_and_reference_check: "Negotiation and \nReference Check",
    result_notification_and_job_confirmation:
      "Result Notification and \nJob Confirmation",
    additional_steps_possible:
      "The above is the basic process of iSciLab recruitment, \nand additional steps may be included depending on the circumstances.",
    software_technology_field: "Software Technology Field",
    coding_test:
      "Conduct coding tests and face-to-face interviews after passing the document screening.",
    non_technical_field:
      "Non-Technical Field (Business Management / Design, etc.)",
    third_interview_possible:
      "Depending on the position, a third interview may be conducted. \nIf you fail in the interview, reapplication for the same position is possible after one year from the date of application. \nDisabled/veteran candidates are preferred in accordance with relevant laws.",
    no_current_job_postings: "There are currently no job postings.",
  },
  contact_us: {
    mode: "en",
    tag: "Contact",
    contact: "Contact",
    subtitle:
      "Thank you for visiting the iSciLab website. \nPlease fill out the form below with your inquiries, and we will respond promptly.",
    form: {
      name: "Name",
      contactType: {
        title: "Inquiry Type",
        placeholder: "Please select the inquiry type",
        select: "Select",
        businessPartnership: "Business/Partnership",
        investmentCooperation: "Investment/Cooperation",
        recruitment: "Recruitment",
        otherInquiries: "Other Inquiries",
      },
      email: {
        title: "Email",
        placeholder: "Email",
      },
      phone: "Phone",
      company: "Company",
      title: "Title",
      comment: {
        title: "Inquiry Details",
        placeholder:
          "Leave detailed information about your inquiry, and we will connect you with the relevant person quickly.",
      },
      file: {
        title: "Attach File",
        description:
          "* Attachments are only allowed for jpg, png, gif, hwp, pdf, ppt, xls, zip files. / Maximum size: 9M",
      },
      submit: "Submit Inquiry",
      cancel: "Cancel",
      check: "Consent to Collection and Use of Personal Information",
    },
    sending: "Sending...",
    success: "Submission successful",
  },
};
