import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";

const Header = () => {
  const { t, i18n } = useTranslation(["main"]);

  const [isMenuHover, setIsMenuHover] = useState(false);

  const handleMouseHover = (num) => {
    setIsMenuHover(num);
  };

  const handleMouseLeave = () => {
    setIsMenuHover(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header id="iSci_header">
      <div className="inner_header">
        {/* 로고 */}
        <div className="logo_isci">
          <Link to="/">
            <span className="ir">{t("iSciLab")}</span>
            <p className="ir_ko">[아이싸이랩]</p>
          </Link>
        </div>

        {/* 헤더 메뉴 */}
        <nav className="iSci_nav">
          <ul className="nav_1depth">
            {/* 회사소개 */}
            <li onMouseLeave={handleMouseLeave}>
              <button onMouseEnter={() => handleMouseHover(1)}>
                <Link className="hoverblue" to="/about">
                  <span>{t("about.title")}</span>
                </Link>
              </button>
              {isMenuHover === 1 && (
                <ul className="nav_sub">
                  <li>
                    <button>
                      <NavHashLink
                        className="sub-link"
                        to="/about#about"
                        smooth={true}
                        duration={500}
                      >
                        {t("about.about")}
                      </NavHashLink>
                    </button>
                  </li>
                  <li>
                    <button>
                      <NavHashLink
                        className="sub-link"
                        to="/about#ceo"
                        smooth={true}
                        duration={500}
                      >
                        {t("about.ceo")}
                      </NavHashLink>
                    </button>
                  </li>
                  <li>
                    <button>
                      <NavHashLink
                        className="sub-link"
                        to="/about#history"
                        smooth={true}
                        duration={500}
                      >
                        {t("about.history")}
                      </NavHashLink>
                    </button>
                  </li>
                  <li>
                    <button>
                      <NavHashLink
                        className="sub-link"
                        to="/about#partners"
                        smooth={true}
                        duration={500}
                      >
                        {t("about.partners")}
                      </NavHashLink>
                    </button>
                  </li>
                  <li>
                    <button>
                      <NavHashLink
                        className="sub-link"
                        to="/about#location"
                        smooth={true}
                        duration={500}
                      >
                        {t("about.location")}
                      </NavHashLink>
                    </button>
                  </li>
                </ul>
              )}
            </li>
            {/* 인증서 및 특허 */}
            <li onMouseLeave={handleMouseLeave}>
              <button onMouseEnter={() => handleMouseHover(2)}>
                <Link className="hoverblue" to="/license">
                  <span>{t("licenses.title")}</span>
                </Link>
              </button>
              {isMenuHover === 2 && (
                <ul
                  className={"nav_sub"}
                  onMouseEnter={() => handleMouseHover(2)}
                  onMouseLeave={handleMouseLeave}
                >
                  <li>
                    <button>
                      <NavHashLink
                        // onClick={(e) => e.preventDefault()}
                        className="sub-link"
                        to="/license#certification"
                      >
                        {t("licenses.certificates")}
                      </NavHashLink>
                    </button>
                  </li>
                  <li>
                    <button>
                      <NavHashLink
                        // onClick={(e) => e.preventDefault()}
                        className="sub-link"
                        to="/license#award"
                      >
                        {t("licenses.award")}
                      </NavHashLink>
                    </button>
                  </li>
                  <li>
                    <button>
                      <NavHashLink
                        // onClick={(e) => e.preventDefault()}
                        className="sub-link"
                        to="/license#patent-and-right"
                      >
                        {t("licenses.patent_and_right")}
                      </NavHashLink>
                    </button>
                  </li>
                </ul>
              )}
            </li>
            {/* 프로젝트 */}
            <li onMouseLeave={handleMouseLeave}>
              <button onMouseEnter={() => handleMouseHover(3)}>
                <Link className="hoverblue" to="/animal-nose">
                  <span>{t("project.title")}</span>
                </Link>
              </button>
              {isMenuHover === 3 && (
                <ul className={"nav_sub"}>
                  <li>
                    <button>
                      <Link className="sub-link" to="/animal-nose">
                        {t("project.animalNose")}
                      </Link>
                    </button>
                  </li>
                  <li>
                    <button>
                      <Link className="sub-link" to="/ai-for-vision">
                        {t("project.visionAiMeta")}
                      </Link>
                    </button>
                  </li>
                </ul>
              )}
            </li>
            {/* 뉴스 */}
            <li onMouseLeave={handleMouseLeave}>
              <button onMouseEnter={() => handleMouseHover(4)}>
                <Link className="hoverblue" to="/news">
                  <span>{t("news.title")}</span>
                </Link>
              </button>
            </li>
            {/* 인재채용 */}
            <li onMouseLeave={handleMouseLeave}>
              <button onMouseEnter={() => handleMouseHover(5)}>
                <Link className="hoverblue" to="/welfare-system">
                  <span>{t("career.title")}</span>
                </Link>
              </button>
              {isMenuHover === 5 && (
                <ul className={"nav_sub"}>
                  <li>
                    <button>
                      <Link className="sub-link" to="/welfare-system">
                        {t("career.welfare")}
                      </Link>
                    </button>
                  </li>
                  <li>
                    <button>
                      <Link className="sub-link" to="/recruitment">
                        {t("career.recruitment")}
                      </Link>
                    </button>
                  </li>
                </ul>
              )}
            </li>
            {/* 문의 */}
            <li>
              <button>
                <Link className="hoverblue" to="/contact-us">
                  <span>{t("contact_us.title")}</span>
                </Link>
              </button>
            </li>
          </ul>
        </nav>

        {/* 언어 변경 */}
        {/* <div
          className="nav_lang_div"
          onMouseLeave={handleMouseLeave}
          onMouseEnter={() => handleMouseHover(6)}
        >
          <button
            className={i18n.language === "ko" ? "lang_kr_over" : "lang_en_over"}
          ></button>
          {isMenuHover === 6 && (
            <ul>
              {i18n.language === "ko" && (
                <>
                  <li className="nav_lang">
                    <button
                      className="lang_en"
                      onClick={() => changeLanguage("en")}
                    ></button>
                  </li>
                </>
              )}
              {i18n.language === "en" && (
                <>
                  <li className="nav_lang">
                    <button
                      className="lang_kr"
                      onClick={() => changeLanguage("ko")}
                    ></button>
                  </li>
                </>
              )}
            </ul>
          )}
        </div> */}
        <div> </div>
      </div>
    </header>
  );
};

export default Header;
