/* eslint-disable import/no-anonymous-default-export */
export default {
  mode: "ko",
  about: {
    main: "아이싸이랩은 Intelligence Science Laboratory의 약자로,",
    sub: "수학기반의 인공지능 연구와 응용, 관련 기술 개발 및 서비스를 주 사업 분야로 하는 최고의 인공지능 기업 입니다.",
    title: "회사소개",
    about: "About Us",
    certificates_and_patents: "인증서 및 특허",
    ceo: "대표 소개",
    organization: "조직",
    history: "연혁",
    partners: "파트너사",
    location: "오시는 길",
  },
  our_work: {
    main: "아이싸이랩은 인공 지능 (딥 러닝 및 기계 학습), 금융, 최적화 및 다양한 분야의 연구를 수행하며 독자적인 기술을 개발하여 특허출원과 함께 전략적 파트너쉽 구축을 통해 기술을 상용화 합니다. 또한 아이싸이랩은 단독 개발 이외에도 협력사와 함께 공동 연구및 개발을 추진합니다.",
    product1: "동물 비문 생체 인식 기술",
    product2: "비전 AI",
    product3: "관련 AI",
    button: "PAST WORKS",
  },
  main_contact_us: {
    main: "아이싸이랩은 인공 지능 (딥 러닝 및 기계 학습), 금융, 최적화 및 다양한 분야의 연구를 수행하며 \n독자적인 기술을 개발하여 특허출원과 함께 전략적 파트너쉽 구축을 통해 기술을 상용화 합니다. \n 또한 아이싸이랩은 단독 개발 이외에도 협력사와 함께 공동 연구 및 개발을 추진합니다.",
    button: "문의하기",
  },
  advertise: {
    GWANGJU_2022: "광주 2022 AI Tech+​",
    ISCILAB_PET_SERVICE: "아이싸이랩의 \n반려동물 비문등록 및 검색 서비스",
    YOON_SEOK_YEOL: "윤석열 대통령의 시연 참관!",
    RESEMBLING_DOGS:
      "너무도 닮은 두 강아지! 코 사진만 찍으면 구별할 수 있다고?! \n영상으로 확인해보세요!",
  },
  licenses: {
    title: "인증서 및 특허",
    certificates: "인증서",
    award: "상장",
    patent_and_right: "특허 및 상표권",
    trademark: "상표권",
    header: "아이싸이랩의 지적 재산권",
    content:
      "비문인식 알고리즘과 기술, 등록 및 검색, 디바이스, 비즈니스 모델 등\n원천 기술 및 비지니스 특허 18건 국내외 등록 및 다수의 추가 특허 출원 중에 있습니다.",
  },
  project: {
    title: "사업영역",
    animalNose: "동물 바이오 인증",
    visionAiMeta: "비전AI",
    visionAiMetaEng: "AI FOR VISION",
    related: "관련 AI",
  },
  animal_nose: {
    what_is_nose_print: "비문이란",
    paradigm_shift: "패러다임 변화",
    microchip_vs_biometrics: "마이크로칩 vs. 비문",
    nose_print: "비문(코무늬)",
    nose_print_text_1:
      "비문이란 동물 코 전반에 분포된 고유한 무늬이며 \n 인간의 지문과 같이 일생 동안 변하지 않아, 개체 식별로 사용이 가능합니다",
    text_books: "비문을 개체인식 수단으로 설명하는 대표적 수의학 교과서",
    paradigm_shift_connection: "인간과 반려동물 관계 패러다임 변화",
    pets_independent_beings:
      "이제 반려동물은 인간의 소유물(물건)이 아닌 독립된 생명체 입니다",
    biometric_technology_features: "바이오 인식 기술 특징",
    microchip_vs_biometrics: "마이크로칩(RFID) vs. 비문 바이오 인식",
    microchip_description:
      "마이크로칩 (RFID)\n(생체 내 주입 또는 목걸이에 부착)",
    biometric_description: "비문 바이오인식",
    guaranteed_uniqueness: "고유성 보장",
    guaranteed_permanence: "영구성 보장",
    guaranteed_compatibility: "호환성 보장",
    prevention_of_tampering: "위·변조 방지",
    ease_of_application: "적용 용이성",
    non_invasive: "비침습\n(non-invasive)",
    loss_prevention: "유실방지",
    cost: "비용",
    high: "높음",
    almost_none: "거의 없음",
    side_effects: "부작용",
    dog_biometrics_video: "강아지 비문 등록, 비문 검색 및 식별 시범 영상",
  },
  news: {
    title: "뉴스",
    iscilabNews: "iSciLab 뉴스",
    industryNews: "산업 뉴스",
    label: "아이싸이랩의 새로운 소식을 전합니다.",
    button: "뉴스 더 보기",
  },
  career: {
    title: "인재채용",
    welfare: "복지제도",
    recruitment: "신규 모집",
  },
  welfare: {
    title: "복지제도",
    iscilab_supports:
      "아이싸이랩은 크루들의 행복과 성장을 아낌없이 지원합니다.",
    holiday_bonus: "명절보너스",
    holiday_bonus_description:
      "행복한 명절을 위해 상품권을 드립니다. (설날, 추석)",
    fitness_training_allowance: "체력단련비 제공",
    fitness_training_allowance_description:
      "매 월 체력단련을 위한 단련비를 지원합니다.",
    unlimited_coffee_snacks_drinks: "커피, 간식, 음료 무제한 제공",
    unlimited_coffee_snacks_drinks_description:
      "임직원들의 에너지 보충을 위한 커피, 음료, 간식을 무제한 제공합니다.",
    meal_support: "식사비 지원",
    meal_support_description: "점심과 저녁(희망자) 식사비를 지원합니다.",
    office_supplies_support: "사무용품 비품 지원",
    office_supplies_support_description:
      "근무에 필요한 모든 비품을 지원합니다.",
    free_organizational_culture: "자유로운 조직 문화",
    casual_attire:
      "눈치 보지 않고 직원 개인이 근무하기 가장 편한 복장으로 근무합니다.",
    talent_profile: "인재상",
    value_individual_opinions: "개인의 의견을 소중하게 생각합니다.",
    derive_rational_opinions: "합리적인 의견을 도출합니다.",
    pursue_common_goal: "하나의 목표를 향해 나아갑니다.",
    mutual_support: "서로서로 돕습니다.",
  },
  recruitment: {
    title: "아이싸이랩 신규 채용",
    description: "아이싸이랩에서 함께 성장할 인재를 모집합니다.",
    recruitment_process: "채용 절차",
    application_submission: "지원서 접수",
    document_screening: "서류 전형",
    interview_process: "면접 전형",
    first_interview: "1차 면접",
    second_interview: "2차 면접 (임원 면접)",
    negotiation_and_reference_check: "처우협의 및\n레퍼런스 체크",
    result_notification_and_job_confirmation: "결과 통보 및 \n입사 확정",
    additional_steps_possible:
      "위는 아이싸이랩 채용의 기본 프로세스이며, 경우에 따라 추가 절차가 포함될 수 있습니다.",
    software_technology_field: "SW 기술 직군",
    coding_test: "서류 전형 합격 후 코딩 테스트 및 대면 인터뷰 진행",
    non_technical_field: "비기술 직군 (사업 관리 / 디자인 직무 등)",
    third_interview_possible:
      "포지션에 따라 3차 면접이 진행될 수 있습니다. \n\n면접에서 불합격한 경우, 동일 직무 재지원은 입사 지원 시점으로부터 1년 후 가능합니다. \n장애/보훈 대상자는 관계 법령에 의거하여 우대합니다.",
    no_current_job_postings: "현재 채용 소식이 없습니다.",
  },
  contact_us: {
    title: "문의",
    tag: "문의",
    contact: "문 의",
    subtitle:
      "아이싸이랩 홈페이지를 방문해 주셔서 감사합니다. \n궁금하신 사항을 아래 양식에 맞게 작성하여 접수해 주시면 \n빠른 시간 내에 답변 드리겠습니다.",
    form: {
      name: "이름",
      contactType: {
        title: "문의 유형",
        placeholder: "문의 유형을 선택해주세요",
        select: "선택",
        businessPartnership: "사업/제휴",
        investmentCooperation: "투자/협력",
        recruitment: "채용",
        otherInquiries: "기타 문의 사항",
      },
      email: {
        title: "이메일",
        placeholder: "이메일",
      },
      phone: "연락처",
      company: "회사명",
      title: "제목",
      comment: {
        title: "문의 내용",
        placeholder:
          "문의사항에 대해 자세한 내용을 남기시면 신속하게 담당자를 연결해 드리겠습니다.",
      },
      file: {
        title: "첨부파일",
        description:
          "* 첨부 파일은 jpg, png, gif, hwp, pdf, ppt, xls, zip 파일에 한해 등록이 가능합니다. / 최대 용량 : 9M",
      },
      submit: "문의하기",
      cancel: "취소",
      check: "개인정보 수집 및 이용 동의",
    },
    sending: "전송중...",
    success: "전송에 성공했습니다.",
  },
};
