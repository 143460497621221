/* eslint-disable no-lone-blocks */
import React from "react";
import sub_visual from "../lib/images/sub_visual.png";
import location_home from "../lib/images/location_home.png";
import { Link } from "react-router-dom";

const SubHeader = (props) => {
  const { location, url } = props;
  return (
    <>
      <div className="sub_visual">
        <div className="inner_visual">
          <img src={sub_visual} alt="" />
          <h2>{location[location.length - 1]}</h2>
        </div>
      </div>
      <div className="sub_location">
        <div className="inner_location">
          <div className="location">
            <Link to="/">
              <img src={location_home} alt="" />
            </Link>
            {location.map((item, index) => {
              {
                if (index === location.length - 1) {
                  return (
                    <>
                      <span>{" > "}</span>
                      <Link to={url}>
                        <span className="on">{item}</span>
                      </Link>
                    </>
                  );
                } else if (index != location.length) {
                  return (
                    <>
                      <span>{" > "}</span>
                      <Link to={url}>
                        <span>{item}</span>
                      </Link>
                    </>
                  );
                }
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubHeader;
