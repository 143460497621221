import React, { useState } from "react";
import { Link } from "react-router-dom";
import news_arrow_over from "../lib/images/news-arrow-over.png";
import news_arrow from "../lib/images/news-arrow.png";

const NewsContents = (props) => {
  const { className, newsList, title, text, insertDate, key, url, img } = props;
  const [img_src, setImg_src] = useState(news_arrow);

  const handleMouseOver = () => {
    setImg_src(news_arrow_over);
  };

  const handleMouseOut = () => {
    setImg_src(news_arrow);
  };

  /** @param txt
   *  @param len
   *  @param lastTxt
   *  @returns 결과값
   */
  function textLengthOverCut(txt, len = 80, lastTxt = "...") {
    if (txt.length > len) {
      txt = txt.substr(0, len) + lastTxt;
    }
    return txt;
  }

  return (
    <div
      className={className}
      onMouseOver={(event) => {
        handleMouseOver();
      }}
      onMouseOut={(event) => {
        handleMouseOut();
      }}
    >
      <Link
        to={{
          pathname: url,
          state: {
            title: title,
            text: text,
            insertDate: insertDate.substring(0, 10),
            key: key,
            img: img,
          },
        }}
      >
        <span className="tit_bold">{textLengthOverCut(title)}</span>
        <span className="text">
          {textLengthOverCut(text)}
          <br />
        </span>
        <img alt="arrow" src={img_src} />
      </Link>
    </div>
  );
};

export default NewsContents;
