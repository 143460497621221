/* eslint-disable import/no-anonymous-default-export */
export default {
  mode: "ko",
  iSciLab: "iSciLab",
  about: {
    title: "회사소개",
    about: "About Us",
    ceo: "대표 소개",
    organization: "조직",
    history: "연혁",
    partners: "파트너사",
    location: "오시는 길",
  },
  solution: { title: "솔루션", visionAi: "vision AI" },
  project: {
    title: "프로젝트",
    animalNose: "비문",
    visionAiMeta: "비전AI & 메타버스",
    related: "관련 AI",
  },
  news: {
    title: "뉴스",
    iscilabNews: "iSciLab News",
    IndustryNews: "Industry News",
  },
  career: { title: "인재채용", welfare: "복지제도", recruitment: "신규 모집" },
  contact: "문의",
  copyright: "COPYRIGHT 2024. Ⓒ iSCILAB. ALL RIGHTS RESERVED.",
};
