import React from "react";
import "../styles/Paging.css";
import Pagination from "react-js-pagination";

export default function Paging({ page, count, setPage, postsPerPage }) {
  const handlePageChange = (e) => {
    setPage(e);
  };
  return (
    <Pagination
      activePage={page} //현재 페이지
      itemsCountPerPage={postsPerPage} //한 페이지당 아이템 개수
      totalItemsCount={count} //총 아이템 개수
      pageRangeDisplayed={10} //보여지는 페이지 범위
      prevPageText={<p>　</p>}
      nextPageText={<p>　</p>}
      firstPageText={<p>　</p>}
      lastPageText={<p>　</p>}
      onChange={(e) => handlePageChange(e)}
    />
  );
}
