/* eslint-disable import/no-anonymous-default-export */
export default {
  mode: "ko",
  tag: "문의",
  contact: "문 의",
  subtitle:
    "아이싸이랩 홈페이지를 방문해 주셔서 감사합니다. \n궁금하신 사항을 아래 양식에 맞게 작성하여 접수해 주시면 \n빠른 시간 내에 답변 드리겠습니다.",
  form: {
    name: "이름",
    contactType: {
      title: "문의 유형",
      select: "선택",
      businessPartnership: "사업/제휴",
      investmentCooperation: "투자/협력",
      recruitment: "채용",
      otherInquiries: "기타 문의 사항",
    },
    email: {
      title: "이메일",
      placeholder: "이메일",
    },
    phone: "연락처",
    company: "회사명",
    title: "제목",
    comment: {
      title: "문의 내용",
      placeholder:
        "문의사항에 대해 자세한 내용을 남기시면 신속하게 담당자를 연결해 드리겠습니다.",
    },
    file: {
      title: "첨부파일",
      description:
        "* 첨부 파일은 jpg, png, gif, hwp, pdf, ppt, xls, zip 파일에 한해 등록이 가능합니다. / 최대 용량 : 9M",
    },
    submit: "문의하기",
    cancel: "취소",
    check: "개인정보 수집 및 이용 동의",
  },
};
