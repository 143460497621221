import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//image
import img_ow00 from "../lib/images/img_ow00.png";
import img_ow01 from "../lib/images/img_ow01.png";
import scroll from "../lib/images/scroll.png";

//component
import CustomSlider from "../components/CustomSlider.js";
import LandingNews from "./LandingPage/LandingNews";
import { Grid } from "@mui/material";

function LandingPage(props) {
  const { t } = useTranslation(["main"]);

  const [newsList, setNewsList] = useState(null);
  const dataList = [];

  useEffect(() => {
    axios
      .get(`api/news/landing`)
      .then(({ data }) => {
        if (data.length > 0) {
          data.map((news) => dataList.push(news));
          setNewsList(dataList);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <div id="iSci_contents">
      <div className="intro">
        <div className="main_visual">
          <div className="main_carousel">
            <CustomSlider />
          </div>
        </div>

        <div className="landing_scroll">
          <img src={scroll} alt="" />
        </div>
      </div>

      <section className="advertise_background">
        <Grid className="advertise" container spacing={0}>
          <Grid item sx={12} xs={12} md={12} lg={7}>
            <div className="advertise_text">
              <h2 className="advertise_header">
                {t("advertise.GWANGJU_2022")}
                <br />
                <strong className="highlight">
                  {t("advertise.ISCILAB_PET_SERVICE")}
                  <br />
                  <strong className="line">
                    {t("advertise.YOON_SEOK_YEOL")}
                  </strong>
                </strong>
              </h2>
              <p>{t("advertise.RESEMBLING_DOGS")}</p>
            </div>
          </Grid>

          <Grid item sx={12} xs={12} md={12} lg={5}>
            <div className="video_area">
              <iframe
                className="video"
                src="https://www.youtube.com/embed/wXqV5ntz9N0?autoplay=1&mute=1&start=0"
                title="YouTube video player"
                frameborder="0"
                autoplay="1"
                mute="1"
                allow="start; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </Grid>
        </Grid>
      </section>

      <section className="main_background">
        <div className="main_about">
          <h2 className="about_iSci">
            <strong className="line">About</strong> iSciLab
          </h2>
          <p>
            {t("about.main")}
            <br />
            {t("about.sub")}
          </p>
        </div>
      </section>
      <section className="ourwork_background">
        <div className="main_ourwork">
          <h2 className="ourwork_iSci">
            <strong className="line">Our</strong> Work
          </h2>

          <p>{t("our_work.main")}</p>

          <div className="ourwork_contents">
            <ul>
              <li>
                <Link to="/animal-nose">
                  <div className="work">
                    <img src={img_ow00} alt="" />
                    <div className="work_text">
                      <span className="tit_bold">ANIMAL NOSE BIO METRICS</span>
                      <span className="text">{t("our_work.product1")}</span>
                    </div>
                  </div>
                </Link>

                <Link to="/ai-for-vision">
                  <div className="work">
                    <img src={img_ow01} alt="" />
                    <div className="work_text">
                      <span className="tit_bold">AI FOR VISION</span>
                      <span className="text">{t("our_work.product2")}</span>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {newsList && <LandingNews newsList={newsList} />}
      <section className="contactus_background">
        <div className="main_contactus">
          <h2 className="contactus_iSci">Contact Us</h2>
          <p>
            {t("main_contact_us.main")
              .split("\n")
              .map((line) => (
                <div>{line}</div>
              ))}
          </p>

          <button className="btn_contactus">
            <Link to="/contact-us">{t("main_contact_us.button")}</Link>
          </button>
        </div>
      </section>
    </div>
  );
}

export default LandingPage;
