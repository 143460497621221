// <===============import CSS===============>
import "./styles/base.css";
import "./styles/common.css";
import "./styles/common-2.css";
import "./static/fonts/font.css";

import { Switch, BrowserRouter, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

import error404 from "./lib/images/error-404.png";
// <===============import pages===============>
import LandingPage from "./pages/LandingPage";
import About from "./pages/About";

import ContactUs from "./pages/ContactUs";

import AnimalNose from "./pages/Projects/AnimalNose";
import VisionAi from "./pages/Projects/VisionAi";

import News from "./pages/News/News";
import NewsDetail from "./pages/News/NewsDetail";

import Recruitment from "./pages/Recruit/Recruitment";
import WelfareSystem from "./pages/Recruit/WelfareSystem";

import TopButton from "./components/TopButton";
import License from "./pages/License";

function App() {
  const contents_css = { "max-width": "100%" };
  if (process.env.NODE_ENV === "production") {
    console.log = function no_console() {};
    console.warn = function no_console() {};
  }
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <div id="iSci_container">
            <Header />

            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/about" component={About} />

              <Route exact path="/license" component={License} />

              <Route exact path="/animal-nose" component={AnimalNose} />
              <Route exact path="/ai-for-vision" component={VisionAi} />

              <Route exact path="/news" component={News} />
              <Route exact path="/news/:id" component={NewsDetail} />

              <Route exact path="/recruitment" component={Recruitment} />
              <Route exact path="/welfare-system" component={WelfareSystem} />
              <Route exact path="/contact-us" component={ContactUs} />

              <Route
                const
                contents_css={"max-width:100%"}
                render={({ location }) => (
                  <div className="error-404">
                    <a href="/">
                      <img
                        style={contents_css}
                        src={error404}
                        alt="404 not found error"
                      />
                    </a>
                  </div>
                )}
              />
            </Switch>
            {/* <TopButton /> */}
            <Footer />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
