import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NewsContents from "../../components/NewsContents";

const LandingNews = (props) => {
  const { t } = useTranslation(["main"]);
  const newsList = props.newsList;

  return (
    <>
      <section className="main_news">
        <div className="tit_news_area">
          <h2 className="news_iSci">
            <strong className="line">NEWS</strong>
          </h2>
        </div>
        <p>{t("news.label")}</p>

        <div className="news_contents">
          <ul>
            <li>
              <NewsContents
                className="news_item"
                newsList={newsList}
                title={newsList[0].title}
                text={newsList[0].text}
                insertDate={newsList[0].insertDate}
                key={newsList[0].id}
                url={`/news/${newsList[0].id}`}
                img={newsList[0].img}
              />
            </li>

            <li>
              <NewsContents
                className="news_item"
                newsList={newsList}
                title={newsList[1].title}
                text={newsList[1].text}
                insertDate={newsList[1].insertDate}
                key={newsList[1].id}
                url={`/news/${newsList[1].id}`}
                img={newsList[1].img}
              />
            </li>

            <li>
              <NewsContents
                className="news_item "
                newsList={newsList}
                title={newsList[2].title}
                text={newsList[2].text}
                insertDate={newsList[2].insertDate}
                key={newsList[2].id}
                url={`/news/${newsList[2].id}`}
                img={newsList[2].img}
              />
            </li>
          </ul>

          <button className="btn_news_more">
            <Link to="/news">
              <span>{t("news.button")}</span>
            </Link>
          </button>
        </div>
      </section>
    </>
  );
};

export default LandingNews;
