import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";

import SubHeader from "../components/sub-header";

// ---image---
import img_ceo from "../lib/images/img_ceo.png";
import img_partners from "../lib/images/img_partners.png";
import history from "../lib/images/history.png";
import map_ko from "../lib/images/map-ko.png";
import map_vietnam from "../lib/images/map-vietnam.png";
import { Box } from "@mui/material";

const About = () => {
  const { t } = useTranslation(["about"]);
  const tag = [t("tag")];
  const url = "about";

  return (
    <div>
      <SubHeader location={tag} url={url} />
      <div id="iSci_sub_contents">
        <div id="about" className="intro_company_nav">
          <ul>
            <li>
              <NavHashLink
                className="anchor_about"
                to="#about"
                smooth={true}
                duration={500}
              >
                {t("nav.about")}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_ceo"
                to="#ceo"
                smooth={true}
                duration={500}
              >
                {t("nav.ceo")}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_history"
                to="#history"
                smooth={true}
                duration={500}
              >
                {t("nav.history")}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_partners"
                to="#partners"
                smooth={true}
                duration={500}
              >
                {t("nav.partners")}
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                className="anchor_location"
                to="#location"
                smooth={true}
                duration={500}
              >
                {t("nav.location")}
              </NavHashLink>
            </li>
          </ul>
        </div>
        {/* <!-- intro company about --> */}
        <section className="intro_company_about">
          <div className="company_box">
            <h2 className="sub_about">
              <strong className="line">About Us</strong>
            </h2>
            <p>
              {t("aboutUs.main")
                .split("\n")
                .map((line) => (
                  <div>{line}</div>
                ))}
            </p>
          </div>
        </section>

        <section id="ceo" className="intro_ceo">
          <div className="div_ceo">
            <div className="tit">
              <h2 className="sub_ceo">
                <strong className="line">{t("ceo.title")}</strong>
              </h2>
            </div>
            <img src={img_ceo} alt="" />
            <p>
              {t("ceo.history")
                .split("\n")
                .map((line) => (
                  <div>{line}</div>
                ))}
            </p>
          </div>
        </section>

        <section id="history" className="intro_history">
          <h2 className="sub_history">
            <strong className="line">{t("history.title")}</strong>
          </h2>
          <p>
            {t("history.subTitle")
              .split("\n")
              .map((line) => (
                <div>{line}</div>
              ))}
          </p>
          <div className="infograph">
            <img src={history} alt="" />
          </div>
        </section>

        <section id="partners" className="intro_partner">
          <h2 className="sub_partner">
            <strong className="line">{t("partners.title")}</strong>
          </h2>
          <img src={img_partners} alt="" />
        </section>

        <section id="location" className="intro_map">
          <h2 className="sub_map">
            <strong className="line">{t("location.title")}</strong>
          </h2>
          <Box className="maps" sx={{ mb: 3 }}>
            <Box className="map">
              <a href="https://goo.gl/maps/315VCyhBrcSMpuTR6">
                <img src={map_ko} alt="아이싸이랩 한국지도" />
              </a>
              <h3 className="bold">{t("location.company_ko.title")}</h3>
              <p>
                {t("location.company_ko.address")
                  .split("\n")
                  .map((line) => (
                    <div>{line}</div>
                  ))}
              </p>
            </Box>
            <Box className="map">
              <a href="https://goo.gl/maps/pQWxMra6u8icQkew8">
                <img src={map_vietnam} alt="아이싸이랩 베트남 지도" />
              </a>
              <h3 className="bold">{t("location.company_vn.title")}</h3>
              <p>
                {t("location.company_vn.address")
                  .split("\n")
                  .map((line) => (
                    <div>{line}</div>
                  ))}
              </p>
            </Box>
          </Box>
        </section>
      </div>
    </div>
  );
};

export default About;
